import React, {Component} from 'react';

import {Container, Image, Tabs, Tab, Badge, Row, Col, Card, Modal, Button, Form, InputGroup, FormControl, Toast} from 'react-bootstrap';
import Profile from '../../assets/images/profile.png';
import Heart from '../../assets/images/heart.png';
import Share from '../../assets/images/share.png';
import Directions from '../../assets/images/directions.png';
import Flag from '../../assets/images/flag.png';
import Play from '../../assets/images/play.png';
import NothingToShow from './../elements/NothingToShow';
import ResponsivePlayer from './../elements/ResponsivePlayer';
import { withAuthorization } from './../session';
import {TwitterShareButton, TwitterIcon, WhatsappShareButton, WhatsappIcon, RedditIcon, RedditShareButton} from 'react-share';
import uuid from 'react-uuid';


class PostDetailSection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            showTooltip: false,
            showImageFullScreen: false,
            isFavorite: undefined,
            showReportModal: false,
            reportText: undefined,
            showShareModal: false,
            error: false,
            errorMessage: undefined
        }
    }

    isFavorite = () => {
        let postId = this.props.match.params.slug;
        return  this.props.appUserConfig.favorites_lookup.filter((p) => p === postId).length > 0;
    }

    handleFavorite = (_) => {
        let authUser = this.props.authUser;
        let postId = this.props.match.params.slug;
        let that = this;

        let {post} = this.props;

        if(post.user_id === authUser.uid) {
            this.setState({
                error: true,
                errorMessage: "You cannot favorite your own post."
            })
            return;
        }

        if (this.isFavorite()) {
            // We need to remove this.
            this.props.appUserConfig.favorites_lookup = that.props.appUserConfig.favorites_lookup.filter((fav) => fav !== postId);
            this.props.firebase
                .appUserConfigs()
                .doc(authUser.uid)
                    .set({
                        favorites_lookup: this.props.appUserConfig.favorites_lookup,
                        updated_at: that.props.firebase.serverTimestamp()
                    }, {merge: true})
                    .then((_) => {
                        that.props.firebase
                            .appUserConfigs()
                            .doc(authUser.uid)
                            .collection('favorites')
                            .doc(postId)
                            .delete()
                            .then((_) => {
                                that.setState({
                                    isFavorite: false
                                })
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                        
                        
                    })
                    .catch((err) => {
                        console.log(err);
                    })
            
        } else {
            that.props.appUserConfig.favorites_lookup.push(postId);
            this.props.firebase
                .appUserConfigs()
                .doc(authUser.uid)
                    .set({
                        favorites_lookup: this.props.appUserConfig.favorites_lookup,
                        updated_at: that.props.firebase.serverTimestamp()
                    }, {merge: true})
                    .then((_) => {
                        that.props.firebase
                            .appUserConfigs()
                            .doc(authUser.uid)
                            .collection('favorites')
                            .doc(postId)
                            .set(that.props.post)
                            .then((_) => {
                                that.setState({
                                    isFavorite: true
                                })
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    })
                    .catch((err) => {
                        console.log(err);
                    })
        }
    }

    componentWillReceiveProps(next) {
        if(next.appUserConfig && !this.state.isFavorite) {
            let postId = next.match.params.slug;
            this.setState({
                isFavorite: next.appUserConfig.favorites_lookup.filter((p) => p === postId).length > 0
            })
        }
    }

    componentDidMount() {
        console.log(this.props.user);

        this.props.firebase.analytics
            .logEvent('post_seen', {
                postId: this.props.match.params.slug,
                author: this.props.user.uid,
                platform: 'web',
                userId: this.props.authUser.uid
            });
        

        // TODO: Temporarily log this using functions
        this.props.firebase.logImpressionsWeb()({
            author: this.props.user.uid,
        }).catch((err) => {
            console.log(err);
        })

    }

    report = (_) => {
        const that = this;

        // Report the post
        if(this.state.reportText !== "" || this.state.reportText !== undefined) {
            this.props.firebase.reports()
                .doc(uuid())
                .set({
                    postAuthor: this.props.user.displayName || "John Doe",
                    postTile: this.props.post.location.title,
                    postImage: this.props.post.thumbnail,
                    reporter: this.props.authUser.uid
                })
                 .then(() => {
                    that.setState({
                        showReportModal: false
                    });
                 })
                .catch((err) => {
                    console.log(err);
                    that.setState({
                        showReportModal: false 
                    });
                })
        }
        
    }

    render() {

        let {user, post, authUser} = this.props;
        
        const mapLink = encodeURI(`https://maps.apple.com?q=${post.location.title}&ll=${post.location.latitude},${post.location.longitude}`);

        const topImageStyle = {
            backgroundImage: `url('${post.thumbnail}')`,
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
        };

        const flightType = post.commercial_flight ? "Commercial Flight" : "Recreational Flight";
        const detailText = post.commercial_flight ? 
            "This flight is a commercial flight. Special permits were probably required and pilot needs to have a Part 103 license." 
            : "This flight was recreational. If there were additional permissions requested, check the permits required tab.";
        
        return(
            <Container fluid>

                <Modal
                    show={this.state.show}
                    onHide={(_) => {
                        this.setState({
                            show: false
                        })
                    }}
                    dialogClassName="app-modal-dark modal-100h"
                    centered
                >
                    <Modal.Body>
                        <ResponsivePlayer />
                    </Modal.Body>
                </Modal>

                <Modal
                    show={this.state.showImageFullScreen}
                    onHide={(_) => {
                        this.setState({
                            showImageFullScreen: false
                        })
                    }}
                    keyboard={false}
                    dialogClassName="app-modal-dark modal-100h"
                    centered
                >
                    <Modal.Body>
                        <Image src={post.thumbnail} />
                    </Modal.Body>
                </Modal>

                <Modal 
                    show={this.state.showReportModal}
                    onHide={(_) => this.setState({ showReportModal: false })}
                    dialogClassName="app-image-modal"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Flag this post</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        
                        <Form.Group controlId="exampleForm.ControlTextarea1">
                            <Form.Control 
                                as="textarea" 
                                rows="4" 
                                className="app-textarea" 
                                placeholder="Describe what do you want to report"
                                onChange={(e) => {
                                    this.setState({
                                        reportText: e.target.value
                                    })
                                }}
                            />
                        </Form.Group>
                        
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="dark" onClick={(_) => this.setState({ showReportModal: false })}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={this.report}>
                            Report
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal 
                    show={this.state.showShareModal}
                    onHide={(_) => this.setState({ showShareModal: false })}
                    dialogClassName="my-modal"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Share with your friends</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        
                    <InputGroup className="mb-3">
                        <FormControl
                            placeholder="Post Link"
                            aria-label="Post Link"
                            aria-describedby="basic-addon2"
                            disabled={true}
                            value={`${window.location.href}`}
                            className="app-textarea"
                        />
                        <InputGroup.Append>
                            <Button variant="dark">Copy Link</Button>
                        </InputGroup.Append>
                    </InputGroup>
                        
                    <h4 style={{
                        textAlign: "center"
                    }}>Share on Social Media</h4>
                    
                    <div
                        className="d-flex justify-content-center"
                    >
                            <TwitterShareButton
                                title={`Flight at ${post.location.title}`}
                                via="dronetrails.app"
                                url={`${window.location.href}`}
                            >
                                <TwitterIcon size={64} round={true} />
                            </TwitterShareButton>
                            
                            <div className="m-2"></div>

                            <WhatsappShareButton
                                title={`Flight at ${post.location.title}`}
                                url={`${window.location.href}`}
                            >
                                <WhatsappIcon size={64} round={true} />
                            </WhatsappShareButton>
                            
                            <div className="m-2"></div>

                            <RedditShareButton
                                title={`Flight at ${post.location.title}`}
                                url={`${window.location.href}`}
                            >
                                <RedditIcon size={64} round={true} />
                            </RedditShareButton>
                        
                    </div>
                        
                    </Modal.Body>
                </Modal>

                <Row xs={1}>
                    <div className="top-image-post-detail" style={topImageStyle}>
                        {post.video ? <Image src={Play} onClick={(_) => {
                            this.setState({show: true})
                        }}/> : null}
                        <Image src={user.profilePhoto || Profile} className={"post-profile-image"} roundedCircle />
                    </div>
                </Row>

                <Row>
                    <Col lg={2}></Col>
                    <Col lg={8} xs={12}>
                        <Row xs={1}>
                            <Col xs={2} md={2} className="profile-button-box clickable-div"

                            onClick={(_) => {
                                window.open(mapLink, "_blank")
                            }}
                            >
                                <div className={"d-flex justify-content-center"}>
                                    <Image src={Directions} className={"post-helper-icon"} roundedCircle /><br/>
                                </div>
                                
                                <div className="post-helper-text">
                                    <p>
                                        <span>Directions</span>
                                    </p>
                                </div>
                            </Col>
                            <Col xs={2} md={2} className="profile-button-box clickable-div"
                                onClick={(_) => {
                                    if(authUser.uid === post.user_id) {
                                        this.setState({
                                            error: true,
                                            errorMessage: "You cannot report your own post."
                                        })
                                        return;
                                    }

                                    this.setState({showReportModal: true})
                                }}
                            >
                                <div className={"d-flex justify-content-center"}>
                                    <Image src={Flag} className={"post-helper-icon"} roundedCircle /><br/>
                                </div>
                                <div className="post-helper-text">
                                    <p>
                                        <span>Report</span>
                                    </p>
                                    
                                </div>
                            </Col>
                            <Col xs={4} md={4}  className="profile-button-box">
                            </Col>
                            <Col xs={2} md={2}  className="profile-button-box clickable-div"
                                onClick={this.handleFavorite}
                            >
                                <div className={"d-flex justify-content-center"}>
                                    <Image src={Heart} className={"post-helper-icon"} roundedCircle /><br/>
                                </div>
                                <div className="post-helper-text">
                                    <p>
                                        <span>{this.state.isFavorite ? "Unfavorite" : "Favorite" }</span>
                                    </p>
                                </div>
                            </Col>

                            <Col xs={2} md={2} className="profile-button-box clickable-div"
                                onClick={(_) => {
                                    this.setState({
                                        showShareModal: true
                                    })
                                }}
                            >
                                <div className={"d-flex justify-content-center"}>
                                    <Image src={Share} className={"post-helper-icon"} roundedCircle /><br/>
                                </div>
                                <div className="post-helper-text">
                                    <p>
                                        <span>Share</span>
                                    </p>
                                </div>
                            </Col>
                            
                        </Row>
                    </Col>
                    
                    <Col lg={2}></Col>
                </Row>

                <Row>
                    <Col lg={2}></Col>
                    <Col lg={8}>
                        <Row>
                            <div>
                                <h2
                                    style={{
                                        marginBottom: "8px"
                                    }}
                                >{post.location.title}</h2>
                                <p>
                                    <span>Post by {user.displayName}</span><br/>
                                    <span>Posted on {new Date(post.created_at.seconds * 1000).toDateString()}</span>
                                </p>
                                
                            </div>
                        </Row>

                        <Row>
                            <div>
                                <h4>
                                {post.tags.map((tag) => {
                                    return(
                                        <>
                                            <Badge variant="secondary">{tag}</Badge>{'     '}
                                        </>
                                    )
                                })}
                                </h4>
                            </div>
                        </Row>

                        <Row xs={1}>
                            <Col lg={6} xs={12}>
                                <Card
                                bg={"dark"}
                                text={'white'}
                                >
                                    <Card.Header>{flightType}</Card.Header>
                                    <Card.Body>
                                        <Card.Text>
                                            {detailText}
                                        </Card.Text>
                                    </Card.Body>
                                </Card>

                            </Col>
                            <Col lg={6} xs={12}>

                                <Card
                                bg={"dark"}
                                text={'white'}
                                >
                                    <Card.Header>Shot using {post.drone.name}</Card.Header>
                                    <Card.Body>
                                        <Card.Text>
                                            This drone is built by {post.drone.manufacturer}. For more information
                                            on the product, visit <a href={"https://www.dji.com/"}>https://www.dji.com/</a>.
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>

                        <Row className="p-4"></Row>

                        <Tabs fill variant="pills" defaultActiveKey="description" id="uncontrolled-tab-example" bg="dark">
                            <Tab eventKey="description" title="Description">
                                <div className="p-2"></div>

                                <p className="post-description">
                                {post.description.split("\\n").map(function(item) {
                                    return (
                                        <>
                                            {item}
                                            <br/>
                                        </>
                                    )
                                })}

                                {post.unsplash_username && post.photo_by ?
                                    <>
                                        <br />
                                        Photo by <a href={`https://unsplash.com/@${post.unsplash_username}?utm_source=Drone%20Trails&utm_medium=referral`}>{post.photo_by}</a> on <a href="https://unsplash.com/?utm_source=Drone%20Trails&utm_medium=referral">Unsplash</a>
                                    </>
                                : null}
                                </p>
                                
                            </Tab>
                            <Tab eventKey="watch-out-for" title="Watch out for">
                                <div className="p-2"></div>
                                {post.warnings.length > 0 ?
                                    post.warnings.map((warning, idx) => {
                                        return(
                                            <Card bg="dark" key={idx}>
                                                <Card.Body>{warning}</Card.Body>
                                            </Card>
                                        )
                                    })
                                    : <NothingToShow />
                                }
                                <div className="p-2"></div>
                            </Tab>
                            <Tab eventKey="permits-required" title="Permits Required">
                                <div className="p-2"></div>
                                {post.permits.length > 0 ?
                                    post.permits.map((permit, idx) => {
                                        return(
                                            <Card bg="dark" key={idx}>
                                                <Card.Body>{permit}</Card.Body>
                                            </Card>
                                        )
                                    })
                                    : <NothingToShow />
                                }
                                <div className="p-2"></div>
                            </Tab>
                        </Tabs>

                    </Col>
                    <Col lg={2}>
                        {this.state.error ?
                            <Toast 
                                className="float-right bg-dark"
                                onClose={() => {
                                    this.setState({
                                        error: false
                                    })
                                }}
                                style={{
                                    position: "fixed",
                                    right: "20px",
                                    top: "20px"
                                }}
                            >
                                <Toast.Header className="bg-dark">
                                    <strong className="mr-auto">Error</strong>
                                </Toast.Header>
                                <Toast.Body>{this.state.errorMessage}</Toast.Body>
                            </Toast>
                            : null
                        }
                    </Col>

                </Row>
                    
                </Container>
        );
    }
}

export default withAuthorization(PostDetailSection);