import React from 'react';
import {Container} from 'react-bootstrap';


const NothingToShow = ({
    className,
    value
}) => {

    let textValue = value || "Nothing to Show";

    return(
        <Container>
            <div
                style={{
                    width: "100%",
                    height: "200px"
                }}
                className="d-flex justify-content-center"
            >
                <h2>{textValue}</h2>
            </div>
            
        </Container>
    )
}

export default NothingToShow;