import React from 'react';
import {Container, Row, Image} from 'react-bootstrap';
import Warning from './../../assets/images/warning.svg';

// Generic component that shows some sort of error happened.

const Error = ({
    headerText,
    descriptionText
}) => {

    let header = headerText || "Something went wrong.";
    let description = descriptionText || "If you see continued degradation - contact Support.";

    return(
        <div
            style={{
                width: '100%',
                height: '80vh',
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }}
        >
            <Container fluid>
                <Row xs={1}>
                    <div className="d-flex justify-content-center">
                        <Image src={Warning} />
                    </div>
                </Row>
                <Row xs={1}>
                    <div className="d-flex justify-content-center">
                        <h2>{header}</h2>
                    </div>
                </Row>
                <Row xs={1}>
                    <div className="d-flex justify-content-center">
                        <p>
                            {description}
                        </p>
                    </div>
                </Row>
            </Container>
        </div>
    )
}


export default Error;