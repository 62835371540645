import React from 'react';
import {Container, Row, Col, Nav} from 'react-bootstrap';
import logo from './../../assets/images/logo.svg';
import appStoreLogo from './../../assets/images/download-on-app-store.svg';
import {Link} from 'react-router-dom';
import * as ROUTES from './../../constants/routes';


const Footer = ({
    className
}) => {
    return(
        <Container fluid className={className}>
            <Row className="p-2"></Row>
            <Row>
                <Col lg={1}></Col>
                <Col lg={10}>
                        <Row xs={1} md={2}>
                            <Col lg={2} className="d-flex justify-content-center">
                                <img
                                    alt="Drone Trails logo"
                                    src={logo}
                                    width="256"
                                    height="256"
                                />
                            </Col>
                            <Col lg={8}>

                                <div className="d-flex justify-content-center">
                                    <Nav>
                                        <Link to={ROUTES.TERMS_OF_SERVICE} className="nav-link">Terms of Use</Link>
                                        <Link to={ROUTES.PRIVACY} className="nav-link">Privacy Policy</Link>
                                        <Link to={ROUTES.CONTACT} className="nav-link">Contact Us</Link>
                                        <Link to={ROUTES.ABOUT} className="nav-link">About</Link>
                                    </Nav>
                                </div>

                            </Col>
                            <Col xs={12} lg={1} className="d-flex justify-content-center">
                                <a href="https://apps.apple.com/us/app/drone-trails/id1518831280">
                                    <img
                                        src={appStoreLogo}
                                        alt="Download on App Store"
                                        style={{
                                            marginTop: "8px"
                                        }}
                                    />
                                </a>
                            </Col>
                            <Col xs={12} lg={1} className="d-flex justify-content-center">
                                <a href="https://www.buymeacoffee.com/dronetrails" target="_blank">
                                    <img
                                        src={"https://cdn.buymeacoffee.com/buttons/v2/default-yellow.png"}
                                        alt="Buy Me A Coffee"
                                        style={{
                                            height: "40px",
                                            width: "120px",
                                            marginTop: "8px"
                                        }}
                                    />
                                </a>
                            </Col>
                        </Row>
                </Col>
                <Col lg={1}></Col>
            </Row>
            <Row className="p-2">

            </Row>
            <Row>
                <Col lg={1}></Col>
                <Col lg={11}>
                    <p>
                        Made with love in California and Unsplash.
                    </p>
                </Col>
            </Row>
        </Container>
    );
}

export default Footer;