import React from 'react';
 
const FirebaseContext = React.createContext(null);

export const withFirebase = Component => props => {
    return(
      <FirebaseContext.Consumer>
        {firebase =>
          firebase ? <Component {...props} firebase={firebase} /> : null
        }
      </FirebaseContext.Consumer>
    );
};

export default FirebaseContext;