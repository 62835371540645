import React from 'react';
import { withFirebase } from './../firebase';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import Bugsnag from '@bugsnag/js';

const AuthUserContext = React.createContext(null);
 
export const withAuthentication = Component => {
    class WithAuthentication extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                authUser: null,
                appUserConfig: null,
                appUserMetadata: null,
                isAnonymous: true
            };
        }

        // helper function to reload config after setting and assigning state.
        reloadConfigs(uid) {
            let that = this;
            this.props.firebase.appUserMetadata()
                .doc(uid)
                .get()
                .then((doc) => {
                    if(doc.exists) {
                        that.setState({
                            appUserMetadata: doc.data()
                        })

                        that.props.firebase.appUserConfigs()
                            .doc(uid)
                            .get()
                            .then((doc) => {
                                if(doc.exists) {
                                    that.setState({
                                        appUserConfig: doc.data()
                                    })
                                }
                            })
                    }
                })
        }

        componentDidMount() {
            let that = this;
            this.listener = this.props.firebase.auth.onAuthStateChanged(
              authUser => {

                if (authUser !== null) {
                    this.setState({authUser: authUser, isAnonymous: false})
                    
                    if (!authUser.isAnonymous) {
                        let uid = authUser.uid
                        that.props.firebase.appUserMetadata()
                            .doc(uid)
                            .get()
                            .then((doc) => {
                                if(doc.exists) {
                                    that.setState({
                                        appUserMetadata: doc.data()
                                    })

                                    // We are now very sure app user config also exists.
                                    that.props.firebase.appUserConfigs()
                                        .doc(uid)
                                        .get()
                                        .then((doc) => {
                                            if(doc.exists) {
                                                that.setState({
                                                    appUserConfig: doc.data()
                                                })
                                            }
                                        })
                                } else {
                                    // The docs don't exist - so let's create it.
                                    that.props.firebase.appUserMetadata()
                                        .doc(uid)
                                        .set({
                                            available_for_projects: false,
                                            commercial_pilot: false,
                                            displayName: authUser.displayName || "John Doe",
                                            created_at: that.props.firebase.serverTimestamp(),
                                            updated_at: that.props.firebase.serverTimestamp()
                                        })
                                        .then(() => {
                                            that.props.firebase.appUserConfigs()
                                                .doc(uid)
                                                .set({
                                                    favorites_lookup: [],
                                                    is_blocked: false,
                                                    is_paid: false,
                                                    impressions: 0,
                                                    base_location: {
                                                        title: "San Franciso",
                                                        subtitle: "California",
                                                        latitude: 37.7749,
                                                        longitude: 122.4194,
                                                        city: "San Francisco",
                                                        state: "CA",
                                                        country_code: "US"
                                                    },
                                                    created_at: that.props.firebase.serverTimestamp(),
                                                    updated_at: that.props.firebase.serverTimestamp()
                                                })
                                                .then(() => {
                                                    // Finally call reload configs to set metadata
                                                    that.reloadConfigs(uid);
                                                })
                                        })
                                }
                            })
                            .catch((error) => {
                                Bugsnag.notify(error);
                            })
                    }
                    
                } else {
                    // TODO: Before signing in anonymously, try to check local storage.
                    this.props.firebase.signInAnonymously();
                    this.setState({
                        authUser: null,
                        isAnonymous: true
                    });
                }
              },
            );
        }

        componentWillUnmount() {
            this.listener();
        }

        
        render() {
            return (
                <AuthUserContext.Provider 
                    value={{
                        authUser: this.state.authUser,
                        appUserConfig: this.state.appUserConfig,
                        appUserMetadata: this.state.appUserMetadata,
                        isAnonymous: this.state.isAnonymous
                    }}>
                    <Component {...this.props} />
                </AuthUserContext.Provider>
            );
        }
    }
   
    return withFirebase(WithAuthentication);
};


export const withAuthorization = Component => {

    class WithAuthorization extends React.Component {

        render() {
            return (
              <AuthUserContext.Consumer>
                {appUser => appUser.authUser ?
                    appUser.authUser.isAnonymous ?
                        <Component {...this.props} authUser={appUser.authUser} appUserConfig={appUser.appUserConfig} appUserMetadata={appUser.appUserMetadata}  />
                    : appUser.appUserConfig && appUser.appUserMetadata ?
                        <Component {...this.props} authUser={appUser.authUser} appUserConfig={appUser.appUserConfig} appUserMetadata={appUser.appUserMetadata} />
                    : null
                : null}
              </AuthUserContext.Consumer>
            );
        }
    }

    return compose(
        withRouter,
        withFirebase,
    )(WithAuthorization);
};


export default withAuthentication;