import React from 'react';
import { Jumbotron, Container, Row, Col, Image} from 'react-bootstrap';
import PropTypes from 'prop-types';
import Video from './../elements/Video';
import appStoreLogo from './../../assets/images/download-on-app-store.svg';
import googlePlayStoreLogo from './../../assets/images/google-play-button.svg';

const propTypes = {
    image: PropTypes.string,
    headerText: PropTypes.string,
    description: PropTypes.string,
    highlightText: PropTypes.string,
    deviceImage: PropTypes.string,
    videoSrc: PropTypes.string,
    addCalloutButton: PropTypes.bool,
}

const defaultProps = {
    image: '',
    headerText: '',
    description: '',
    highlightText: '',
    deviceImage: '',
    videoSrc: '',
    addCalloutButton: false,
}

const FeatureSection = ({
    className,
    image,
    headerText,
    description,
    highlightText,
    deviceImage,
    videoSrc,
    addCalloutButton
}) => {

    const topImageStyle = {
        backgroundImage: `url('${image}')`
    };
    const imageExists = image.trim() !== "";
    const deviceImageExists = deviceImage.trim() !== "";
    const videoExists = videoSrc.trim() !== "";

    return(
        <Jumbotron className={className} fluid>
            {imageExists ?
                <div className="top-image" style={topImageStyle}>

                {deviceImageExists ?
                    <div className="d-flex justify-content-center">
                        <Image
                            src={deviceImage}
                            style={{
                                position: "absolute",
                                bottom: "-40px"
                            }}
                        />
                    </div>
                : null
                }



                </div> : null
            }

            {videoExists ? <Video videoSrc={videoSrc} /> : null}    

            <Container>
                <Row>
                    <Col lg={2}></Col>
                    <Col lg={8}>
                        <h1>
                            {headerText} <span className="text-color-primary">{highlightText}</span>
                        </h1>
                    </Col>
                    <Col lg={2}></Col>
                </Row>

                <Row>
                    <Col lg={2}></Col>
                    <Col lg={8}>
                        <p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="400">
                            {description}
                        </p>
                    </Col>
                    <Col lg={2}></Col>
                </Row>

                {addCalloutButton ?
                    <Row>
                        <Col lg={2}></Col>
                        <Col lg={8} className="d-flex justify-content-center">
                        <a href="https://apps.apple.com/us/app/drone-trails/id1518831280">
                            <img
                                src={appStoreLogo}
                                alt="Download on App Store"
                                style={{
                                    width: '140px',
                                    height: '80px'
                                }}
                            />
                        </a>
                        <a href="https://play.google.com/store/apps/details?id=app.dronetrails">
                            <img
                                src={googlePlayStoreLogo}
                                alt="Download on App Store"
                                style={{
                                    width: '160px',
                                    height: '80px'
                                }}
                            />
                        </a>
                            
                        </Col>
                        <Col lg={2}></Col>
                    </Row>
                : null}
            </Container>
            
        </Jumbotron>
    );
}

FeatureSection.propTypes = propTypes;
FeatureSection.defaultProps = defaultProps;

export default FeatureSection;