import React from 'react';
import {Row, Col, Card, FormControl, Image, Button, Form} from 'react-bootstrap';

import Header from '../components/elements/Header';
import Footer from '../components/elements/Footer';
import ContactUsBanner from './../assets/images/contact-us-banner.svg';
import ReCAPTCHA from "react-google-recaptcha";

class Contact extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            firstName: undefined,
            lastName: undefined,
            email: undefined,
            message: undefined,
            captchaCleared: undefined
        };
    }

    onCaptchaValueAcquired = (_) => {
        this.setState({
            captchaCleared: true
        })
        
    };

    onExpired = (_) => {
        this.setState({
            captchaCleared: undefined
        })
    }

    submitReport = (_) => {
        // Only allow submission if captcha has cleared.
        if (this.state.captchaCleared) {

        }
    }

    render() {
        return(
            <div>
                <Header />
                <Image 
                    src={ContactUsBanner}
                    width="100%"
                />
                <Row>
                    <Col lg={2}></Col>
                    <Col lg={8}>
                        <div className="p-4"></div>
                        <Row xs={1}>
    
                            <Card variant="dark" bg="dark">
                                <Card.Header>Contact Form</Card.Header>
                                <Card.Body>
                                    <Form>
                                        <Row>
                                            <Col lg={6} xs={12}>
                                                <FormControl
                                                    placeholder="First Name"
                                                    aria-label="Reporter's First Name"
                                                    aria-describedby="basic-addon2"
                                                    required="true"
                                                />
                                            </Col>
                                            <Col lg={6} xs={12}>
                                                <FormControl
                                                    placeholder="Last Name"
                                                    aria-label="Reporter's Last Name"
                                                    aria-describedby="basic-addon2"
                                                    required="true"
                                                />
                                            </Col>
                                        </Row>
                                        <div className="p-3"></div>
        
                                        <Row>
                                            <Col lg={12}>
                                                <FormControl
                                                    placeholder="Email"
                                                    aria-label="Reporter's Email"
                                                    aria-describedby="basic-addon2"
                                                    type="email"
                                                    required="true"
                                                />
                                            </Col>
                                        </Row>
                                        <div className="p-3"></div>
        
                                        <Row>
                                            <Col lg={12}>
                                                <FormControl
                                                    placeholder="Subject"
                                                    aria-label="Report's subject"
                                                    aria-describedby="basic-addon2"
                                                    required="true"
                                                />
                                            </Col>
                                        </Row>
                                        <div className="p-3"></div>
        
                                        <Row>
                                            <Col lg={12}>
                                                <FormControl 
                                                    as="textarea" 
                                                    aria-label="Report's Message" 
                                                    rows="5" 
                                                    placeholder="Message" 
                                                    required="true"
                                                />
                                            </Col>
                                        </Row>
                                        
                                        <div className="p-2"></div>

                                        <Row>
                                            <Col lg={12}>
                                                <ReCAPTCHA
                                                    sitekey="6LfabqkZAAAAAAwG-tUo8bLMUVnWMyhaMW3kyAfN"
                                                    onChange={this.onCaptchaValueAcquired}
                                                    theme="dark"
                                                    onExpired={this.onExpired}
                                                />
                                            </Col>
                                            
                                        </Row>
        
                                        <div className="p-2"></div>

                                        <Row>
                                            <Col lg={12}>
                                                <Button size="lg" type="submit">Submit</Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Card.Body>
                            </Card>
                        </Row>
                    </Col>
                    <Col lg={2}></Col>
                </Row>
                <div className="p-2"></div>
                <Footer className="app-footer" />
            </div>
        )
    }
}


export default Contact;