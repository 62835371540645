
import React from 'react';
import Header from '../components/elements/Header';
import Footer from '../components/elements/Footer';
import FeatureSection from '../components/layouts/FeatureSection';
import CardFeatureSection from '../components/layouts/CardFeatureSection';
import ExploreDeviceMarketingImage from './../assets/images/explore-device-marketing-image.png';
import HeartIcon from './../assets/images/heart.svg';
import DirectionsIcon from './../assets/images/drive.svg';
import VideoIcon from './../assets/images/video.svg';
import WebAppMock from './../assets/images/web-app-mock.png';


const Home = () => {
    const cards = [
        {
            title: "Get driving directions.",
            description: "With a single click, get driving directions to your next flight.",
            headlineImage: "https://images.unsplash.com/photo-1601902761797-b35e4a33a250?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=400&fit=max&ixid=eyJhcHBfaWQiOjE2NTAyNX0",
            iconSrc: DirectionsIcon
        },
        {
            title: "Save your favorite flights.",
            description: "Keep a list of flights you want to explore later. You won't be losing the next adventure that easily.",
            headlineImage: "https://images.unsplash.com/photo-1524061205084-3ef19a3a7a8b?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=400&fit=max&ixid=eyJhcHBfaWQiOjE2NTAyNX0",
            iconSrc: HeartIcon
        },
        {
            title: "Sneak peak of your next flight",
            description: "All flights feature a stunning image and an optional video to give you a glimse of what you can capture.",
            headlineImage: "https://images.unsplash.com/photo-1551861212-c4917aa26fc0?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=400&fit=max&ixid=eyJhcHBfaWQiOjE2NTAyNX0",
            iconSrc: VideoIcon
        }
    ];
    
    return (
        <>
            <Header 
                activeKey="/home"
            />

            <FeatureSection 
                className="jumbotron-app jumbotron-app-first"
                headerText="Discover and Share your amazing"
                highlightText="flights"
                description="Picking your drone should be the hard part, not knowing where to fly. Our goal is simple - help you discover safe and legal spots to fly. We're building a community for 1M+ recreational and commercial flyers to share and create stories."
                image={"https://images.unsplash.com/photo-1524748969064-cf3dabd7b84d?ixlib=rb-1.2.1&q=85&fm=jpg&crop=entropy&cs=srgb&ixid=eyJhcHBfaWQiOjE2NTAyNX0"}
                addCalloutButton={true}
            />

            <FeatureSection 
                className="jumbotron-app"
                headerText="Recreational drone flying made"
                highlightText="easy"
                description="Starting out as a new recreational drone flyer ? With a focus on detailed content - Drone Trails makes it hard for your flight to go wrong. Combining an easy to use interface with rich content, we take out all of the fear from flying."
                image={"https://images.unsplash.com/photo-1589720815038-4b8a386f85df?ixlib=rb-1.2.1&q=85&fm=jpg&crop=entropy&cs=srgb&ixid=eyJhcHBfaWQiOjE2NTAyNX0"}
                deviceImage={ExploreDeviceMarketingImage}
            />

            <CardFeatureSection 
                cards={cards}
            />

            <FeatureSection 
                className="jumbotron-app"
                headerText="Fly with"
                highlightText="confidence"
                description="Drone Trails is different than other apps out there. We collect rich metadata from each post showing flyers what to watch out for and permits required."
                image={"https://images.unsplash.com/photo-1504681869696-d977211a5f4c?ixlib=rb-1.2.1&q=85&fm=jpg&crop=entropy&cs=srgb&ixid=eyJhcHBfaWQiOjE2NTAyNX0"}
            />

            <Footer className="app-footer" />
        
        </>
    );
}

export default Home;