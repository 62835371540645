
import React from 'react';
import { Navbar, Nav, Container, Image } from 'react-bootstrap';
import logo from './../../assets/images/logo.svg';
import {Link} from 'react-router-dom';
import * as ROUTES from './../../constants/routes';


const Header = ({
    activeKey
}) => {
    return(
        <Navbar collapseOnSelect expand="lg" variant="dark" bg="transparent">
            <Container fluid>
                <Navbar.Brand href="#" className="abs">
                    <Image
                        alt=""
                        src={logo}
                        width="256"
                        height="256"
                        
                    />
                </Navbar.Brand>
                
                <Navbar.Toggle aria-controls="collapsingNavbar" />
                <Navbar.Collapse id="collapsingNavbar">
                    <Nav>
                        <Nav.Item>
                            <div className={activeKey === "/home" ? "nav-link active" : "nav-link"}>
                                <Link to={ROUTES.LANDING} className="nav-link">Home</Link>
                            </div>
                        </Nav.Item>
                    </Nav>
                    <hr />
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}


export default Header;