import React from 'react';
import PropTypes from 'prop-types';
import {Card} from 'react-bootstrap';


const propTypes = {
    headlineImage: PropTypes.string,
    iconSrc: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string
}

const defaultProps = {
    headlineImage: '',
    iconSrc: '',
    title: '',
    description: ''
}

const CardFeature = ({
    className,
    headlineImage,
    iconSrc,
    title,
    description
}) => {

    const iconExists = iconSrc !== "";

    const iconClassName = "icon-card";

    return (
            <Card bg="dark" className={className}>
                <Card.Img variant="top" src={headlineImage} alt={"card feature headling image"} />
                {iconExists ? 
                    <img
                        className={iconClassName}
                        src={iconSrc}
                        width="64"
                        height="64"
                        alt="car feature icon"
                    /> : <></>
                }
                
                <Card.Body>
                    <Card.Title>{title}</Card.Title>
                    <Card.Text>
                        {description}
                    </Card.Text>
                </Card.Body>
            </Card>
    );
}

CardFeature.propTypes = propTypes;
CardFeature.defaultProps = defaultProps;

export default CardFeature;