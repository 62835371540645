import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import PropTypes from 'prop-types';
import CardFeature from '../elements/CardFeature';
import {CardDeck} from 'react-bootstrap';

const propTypes = {
    cards: PropTypes.array
}

const defaultProps = {
    cards: []
}

const CardFeatureSection = ({
    className,
    cards
}) => {
    return(
        <Container fluid>
            <Row>
                <Col lg={2}></Col>
                <Col lg={8}>
                    <CardDeck>
                        {cards.map((card, index) => {

                            return (
                                <CardFeature
                                    key={index}
                                    title={card.title}
                                    description={card.description}
                                    headlineImage={card.headlineImage}
                                    iconSrc={card.iconSrc}
                                />
                            )
                        })}
                    </CardDeck>
                </Col>
                <Col lg={2}></Col>
            </Row>
        </Container> 
    );
}

CardFeatureSection.propTypes = propTypes;
CardFeatureSection.defaultProps = defaultProps;

export default CardFeatureSection;