import app from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/analytics';
import 'firebase/functions';
import 'firebase/storage';

import uuid from 'react-uuid';

const prodConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID
};

const devConfig = {
  apiKey: process.env.REACT_APP_DEV_API_KEY,
  authDomain: process.env.REACT_APP_DEV_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DEV_DATABASE_URL,
  projectId: process.env.REACT_APP_DEV_PROJECT_ID,
  storageBucket: process.env.REACT_APP_DEV_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_DEV_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_DEV_APP_ID,
  measurementId: process.env.REACT_APP_DEV_MEASUREMENT_ID
};

console.log(`Using ${process.env.REACT_APP_SETTING} environment.`)
const config = process.env.REACT_APP_SETTING === 'development' ? devConfig : prodConfig;


class Firebase {
    constructor() {
      app.initializeApp(config);
      this.db = app.firestore();
      this.auth = app.auth();
      this.functions = app.functions();
      this.storage = app.storage();
      this.analytics = app.analytics();
    }

    betaSubscribers = () => this.db.collection('beta_subscribers');
    appUserMetadata = () => this.db.collection('user_metadata');
    appUserConfigs = () => this.db.collection('user_config');
    reports = () => this.db.collection('reports');

    post = () => this.db.collection('post');
    drones = () => this.db.collection('drones');

    getNearbyPosts = () => this.functions.httpsCallable('getNearbyPosts');
    getMapkitToken = () => this.functions.httpsCallable('getMapkitToken');
    geohashPost = () => this.functions.httpsCallable('geohashPost');
    logImpressionsWeb = () => this.functions.httpsCallable('logImpressionsWeb');
    
    /*
     * This is a helper function to upload any sort of media.
     * In this case, assetType will be one of (post, profile)
     * identifier will be the key
     * mediaType will be one of photo/video
    */
    addMedia = (buffer, assetType, identifier, name, metadata, mediaType) => {
      if(buffer === undefined || buffer === null) {
          return new Promise((resolve) =>  {resolve(null)});
      }

      var storageRef = this.storage.ref();
      var mediaId = uuid();
      var fileExt = name.split(".").pop();
      var mediaRef = storageRef.child(`${mediaType}/${assetType}/${identifier}/${mediaId}.${fileExt}`);

      if (mediaType === "photo" || mediaType === "video") {
        return mediaRef.put(buffer, metadata)
          .then((_) => {
            return mediaRef.getDownloadURL()
          })
          .catch((err) => {
            console.log(err);
          })
      }
    }

    serverTimestamp = () => {
      return app.firestore.FieldValue.serverTimestamp();
    }

    getCurrentUser = () => {
      return this.auth.currentUser;
    }

    signInAnonymously = () => {
        this.auth.signInAnonymously();
    }

    // ideally we will try to link credentials.
    signInWithApple = () => {
      var provider = new app.auth.OAuthProvider('apple.com');
      provider.addScope('email');
      provider.addScope('name');

      this.auth.signInWithRedirect(provider);
    }

    signInWithGoogle = () => {
      var provider = new app.auth.GoogleAuthProvider();
      provider.addScope('https://www.googleapis.com/auth/userinfo.email')
      this.auth.signInWithRedirect(provider);
    }

    signOut = () => {
      this.auth.signOut();
    }

}

export default Firebase;