import React from 'react';
import {Container} from 'react-bootstrap';
import PropTypes from 'prop-types';

const propTypes = {
    videoSrc: PropTypes.string
};

const defaultProps = {
    videoSrc: ''
}

const Video = ({
    className,
    videoSrc
}) => {
    return(
        <Container className={className}>
            <video id="background-video" loop={true} autoPlay={true} muted={true}>
                <source src={videoSrc} />
                Your browser does not support the video tag.
            </video>
        </Container>
    );
}

Video.propTypes = propTypes;
Video.defaultProps = defaultProps;

export default Video;