import React from 'react';

import Header from '../components/elements/Header';
import Footer from '../components/elements/Footer';
import Error from '../components/elements/Error';

const NotFoundPage = () => {
    return(
        <div>
            <Header />

            <Error
                headerText="Page not found"
                descriptionText="Looks like you're exploring uncharted teritory. Why not create one while you're here."
            />

            <Footer className="app-footer" />
        </div>
    )
}

export default NotFoundPage;