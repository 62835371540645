import React from 'react';
import {Container, Image, Row, Col, Card} from 'react-bootstrap';

import Header from '../components/elements/Header';
import Footer from '../components/elements/Footer';

import Logo from '../assets/images/email-banner.png'

const About = () => {
    return(
        <div>
            <Header />
            <Container fluid>
                <Row xs={1}>
                    <Image 
                        src={Logo}
                        width="100%"
                    />
                </Row>
                <Row>
                    <Col lg={2}></Col>
                    <Col lg={8}>
                        <div className="p-2"></div>

                        <Row xs={1}>
                            <h2 style={{textAlign: "center"}}>About Drone Trails</h2>
                            <Card bg="dark">
                                <Card.Body>
                                    Drone Trails is an application built by soupynoodles.
                                    There exists no application that combines both safe to fly functionality with what shots you can get. 
                                    Having to use both apps like Kittyhawk/Airmaps in combination with Instagram to figure out where to fly and what shots to get is a round-about way of doing things.
                                    <div className="m-4"></div>
                                    Drone Trails attempts to bridge this gap. Maintaining the infrastrucutre is hard work since we host most of the content ourselves. Any feedback appreciated and you are always  welcome to contact support for it.
                                    <div className="m-4"></div>
                                    Drone Trails sets itself apart by asking flyers to include as much information about flights as possible. The information goes a long way in helping other flyers find safe and legal spots.
                                </Card.Body>
                            </Card>
                        </Row>

                        <Row xs={1}>
                            <h2 style={{textAlign: "center"}}>The Technology</h2>
                            <Card bg="dark">
                                <Card.Body>
                                    Drone trails uses a combination of GCP and AWS to provide the fastest experience. Primarily, it uses firebase for most of the functionality. For privacy reasons, Drone Trails only supports "Sign in With Apple".
                                    It also uses Apple Maps as the primary map provider. Drone Trails is aimed for flights in USA and hence Apple Maps works for most use-cases.
                                    <div className="m-4"></div>
                                    The web application is built in React and the iOS in Swift. Serverless functions make it possible to not repeat the functionality in both 
                                    stacks. Email is done using Sendgrid and Bugsnag is used to capture errors. Pagerduty is hooked up for critical incidents and Slack for 
                                    chat ops and information on key conversion events.
                                    <div className="m-4"></div>
                                    If you're curious to find out more - contact us or simply subscribe to soupynoodles's newsletter <a href="https://soupynoodles.substack.com/">here</a>.
                                </Card.Body>
                            </Card>
                        </Row>

                    </Col>
                    <Col lg={2}></Col>

                </Row>
                
                
            </Container>
            <Footer className="app-footer" />
        </div>
    )
}

export default About;