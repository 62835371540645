import React, {Component} from 'react';
import Header from '../components/elements/Header';
import Footer from '../components/elements/Footer';
import {withAuthorization} from '../components/session';
import PostDetailSection from '../components/layouts/ PostDetailSection';
import Loading from './../components/elements/Loading';
import Error from './../components/elements/Error';


class Post extends Component {
    constructor(props) {
        super(props);
        this.state = {
            post: undefined,
            user: undefined,
            error: false,
            errorHeader: undefined,
            errorDescription: undefined
        }
    }

    getUserInformation = (user_id) => {
        this.props.firebase.appUserMetadata().doc(user_id).get()
        .then((doc) => {
            if (doc.exists) {
                var userInfo = doc.data();
                userInfo.uid = doc.id;

                this.setState({
                    user: userInfo
                })
            }
        })
        .catch((error) => {
            console.log(error);
            this.setState({
                error: true
            })
        })
    }

    componentDidMount() {
        let post = this.props.location.post;
        let postId = this.props.match.params.slug;
        let user = this.props.authUser;

        // We want to load the post and user information.
        // If this is not undefined, we can assume that both
        // information was passed.
        if(post === undefined) {
            let that = this;
            this.props.firebase.post().doc(postId).get()
                .then((doc) => {
                    if (doc.exists) {
                        let data = doc.data();

                        if(data.user_id === user.uid) {
                            that.setState({
                                user: this.props.appUserMetadata
                            })
                        } else {
                            that.getUserInformation(data.user_id);
                        }

                        that.setState({
                            post: data
                        });
                    } else {
                        that.setState({
                            error: true
                        })
                    }
                })
                .catch((err) => {
                    let code = err.code;
                    

                    //// only deal with permission denied error for now.
                    if(code === "permission-denied") {

                        that.setState({
                            error: true,
                            errorHeader: "This post is still under review",
                            errorDescription: "In order to ensure quality, we place posts under a review period. Try again at a later time."
                        })

                    } else {
                        that.setState({
                            error: true
                        })
                    }
                })
        } else {
            this.getUserInformation(post.user_id);
            this.setState({
                post: post
            })
        }
    }

    render() {

        let post = this.props.location.post || this.state.post;
        let user = this.props.user || this.state.user;

        return(
            <div>
                <Header />

                {post && user ?
                    <PostDetailSection
                        post={post}
                        user={user}
                    />
                    : this.state.error ? <Error headerText={this.state.errorHeader} descriptionText={this.state.errorDescription} /> : <Loading />
                }
                
                <Footer className="app-footer" />
            </div>
        );
    }
}

export default withAuthorization(Post);