import React from 'react';

import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import * as ROUTES from './constants/routes';

import { withAuthentication } from './components/session';

import Home from './views/Home';
import Post from './views/Post';
import About from './views/About';
import Privacy from './views/Privacy';
import TermsOfService from './views/TermsOfService';
import Contact from './views/Contact';
import NotFoundPage from './views/NotFoundPage';


function App() {
  return (
    <Router>
      <Switch>
        <Route exact path={ROUTES.LANDING} component={Home} />
        <Route path={ROUTES.POST} component={Post} />
        <Route path={ROUTES.ABOUT} component={About} />
        <Route path={ROUTES.PRIVACY} component={Privacy} />
        <Route path={ROUTES.TERMS_OF_SERVICE} component={TermsOfService} />
        <Route path={ROUTES.CONTACT} component={Contact} />
        <Route component={NotFoundPage} />
      </Switch>
    </Router>
  );
}

export default withAuthentication(App);
