import React from 'react';
import {Spinner, Container, Row} from 'react-bootstrap';

const Loading = () => {
    return(
        <div
            style={{
                width: '100%',
                height: '100vh',
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }}
        >

            <Container fluid>
                <Row xs={1}>
                    <div className="d-flex justify-content-center">
                        <Spinner animation="grow" variant="primary" />
                        <Spinner animation="grow" variant="secondary" />
                        <Spinner animation="grow" variant="success" />
                        <Spinner animation="grow" variant="danger" />
                        <Spinner animation="grow" variant="warning" />
                        <Spinner animation="grow" variant="info" />
                        <Spinner animation="grow" variant="light" />
                        <Spinner animation="grow" variant="dark" />
                    </div>
                </Row>
                <Row xs={1}>
                    <div className="d-flex justify-content-center">
                        <h2>Loading...</h2>
                    </div>
                </Row>
            </Container>
        </div>
    )
}


export default Loading;