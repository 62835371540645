import React, {Component} from 'react';

class ResponsivePlayer extends Component {
    render () {
      return (
        <div className='player-wrapper'>
            <video controls className="react-player">
                    <source src="https://firebasestorage.googleapis.com/v0/b/drone-trails.appspot.com/o/video%2Fpost%2FA141C9F0-B65C-4FE2-BF7A-A97E2BA9CF62%2FIMG_9146.mov?alt=media&token=1fc0bb87-38d1-4722-8ca3-f5952dff7957" type="video/mov" />
                    <source src="https://firebasestorage.googleapis.com/v0/b/drone-trails.appspot.com/o/video%2Fpost%2FA141C9F0-B65C-4FE2-BF7A-A97E2BA9CF62%2FIMG_9146.mov?alt=media&token=1fc0bb87-38d1-4722-8ca3-f5952dff7957" type="video/ogg" />
            </video>
        </div>
      )
    }
  }

  export default ResponsivePlayer;